import React, { useCallback, useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import AddLinkIcon from '@mui/icons-material/AddLink';
import { styled } from '@mui/material/styles';
import { IModalProps, SearchModalData } from '../../../core/types';
import BaseModal from '../BaseModal/BaseModal';
import './NewBannerModal.scss';
import { isValidURL } from '../../../core/helpers/helpers';
import { FormikErrors, FormikValues, useFormik } from 'formik';
import { Stack } from '@mui/material';
import SearchModal from '../../SearchModal/SearchModal';
import { useAppSelector } from '../../../state';
import {
  createSearchDataFromUsers,
  createSearchDataFromCategories
} from '../../../core/helpers/model-helpers';
import getGlobal from '../../../core/globals';

const Input = styled('input')({
  display: 'none'
});

interface FormValues {
  image: File;
  link: string;
}

const NewBannerModal = ({ onAccept, onClose, ...props }: IModalProps) => {
  const { users, categories } = useAppSelector((state) => state);
  const [autoGenerateUrl, setAutoGenerateUrl] = useState(false);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const prevImageRef = useRef<HTMLImageElement>(null);
  const { errors, values, handleChange, submitForm, setFieldValue, resetForm } =
    useFormik<FormValues>({
      initialValues: { image: null, link: '' },
      validateOnChange: false,
      validateOnBlur: false,
      validate: (values) => {
        const errors: FormikErrors<FormValues> = {};
        if (!values.image) {
          errors.image = 'Please select an image.';
        }

        if (!isValidURL(values.link)) {
          errors.link = 'Please enter a valid link.';
        }

        return errors;
      },
      onSubmit: async (values: FormikValues, { resetForm }) => {
        await onAccept(values);
        resetForm();
        setAutoGenerateUrl(false);
      }
    });

  useEffect(() => {
    if (!props.isOpen && prevImageRef.current) {
      prevImageRef.current = null;
    }
  }, [props.isOpen, prevImageRef.current]);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files && e.currentTarget.files[0]) {
      const reader = new FileReader();

      setFieldValue('image', e.currentTarget.files[0]);
      reader.onload = () => {
        prevImageRef.current.src = reader.result as string;
      };

      reader.readAsDataURL(e.currentTarget.files[0]);
    }
  };

  const handleSelectCategory = (category: SearchModalData) => {
    const url = `${getGlobal('appUrl')}/category/${category.value}`;
    setFieldValue('link', url);
  };

  const handleSelectUser = (user: SearchModalData) => {
    const url = `${getGlobal('appUrl')}/creators/${user.value}`;
    setFieldValue('link', url);
  };

  const handleAutoGenerateUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAutoGenerateUrl(e.target.checked);
  };

  const handleShowCategoriesModal = () => setIsCategoryModalOpen(true);
  const handleShowUsersModal = () => setIsUserModalOpen(true);

  const handleCloseCategoriesModal = () => setIsCategoryModalOpen(false);
  const handleCloseUsersModal = () => setIsUserModalOpen(false);

  const handleOnClose = () => {
    onClose();
    resetForm();
    setAutoGenerateUrl(false);
  };

  return (
    <BaseModal
      title='Create Banner'
      onAccept={submitForm}
      onClose={handleOnClose}
      {...props}
    >
      <div className='NewBannerModal__banner'>
        <img className='NewBannerModal__bannerImage' ref={prevImageRef} />
        <label htmlFor='contained-button-file'>
          <Input
            accept='image/*'
            id='contained-button-file'
            multiple
            type='file'
            onChange={handleImageChange}
          />
          <Button
            variant='outlined'
            component='span'
            color='secondary'
            startIcon={<PhotoCamera />}
          >
            Browse Gallery
          </Button>
        </label>
      </div>

      {errors.image && (
        <div className='NewBannerModal__imageError'>{errors.image}</div>
      )}

      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              onChange={handleAutoGenerateUrl}
              checked={autoGenerateUrl}
            />
          }
          label='Auto Generate URL'
        />
        {autoGenerateUrl && (
          <Stack spacing={2} direction='row'>
            <Button
              variant='outlined'
              color='secondary'
              size='small'
              startIcon={<AddLinkIcon />}
              onClick={handleShowCategoriesModal}
            >
              Category
            </Button>
            <Button
              variant='outlined'
              color='secondary'
              size='small'
              startIcon={<AddLinkIcon />}
              onClick={handleShowUsersModal}
            >
              User
            </Button>
          </Stack>
        )}
      </FormGroup>

      <TextField
        id='link'
        name='link'
        label='Enter a link for this banner'
        variant='standard'
        value={values.link}
        onChange={handleChange}
        error={!!errors.link}
        helperText={errors.link ?? ''}
        fullWidth
      />

      <SearchModal
        title='Select Category'
        open={isCategoryModalOpen}
        data={categories.map(createSearchDataFromCategories)}
        dataLimit={50}
        onSelect={handleSelectCategory}
        onClose={handleCloseCategoriesModal}
      />

      <SearchModal
        title='Select User'
        open={isUserModalOpen}
        data={users.map(createSearchDataFromUsers)}
        onSelect={handleSelectUser}
        onClose={handleCloseUsersModal}
      />
    </BaseModal>
  );
};

export default NewBannerModal;
