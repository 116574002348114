import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Avatar from '@mui/material/Avatar';
import { SearchModalData } from '../../core/types';
import { useEffect, useState } from 'react';
import { getMediaFile } from '../../core/api';
import { deepPurple } from '@mui/material/colors';
import { useSimpleSearch } from '../../hooks/useSimpleSearch';
import './SearchModal.scss';
import { NumberLiteralType } from 'typescript';

interface Props {
  open: boolean;
  data: SearchModalData[];
  title?: string;
  description?: string;
  dataLimit?: number;
  onSelect?: (item: SearchModalData) => void;
  onClose: (e?: React.MouseEvent) => void;
}

const SearchModal = ({
  open,
  data,
  title,
  description,
  dataLimit = 10,
  onSelect,
  onClose
}: Props) => {
  const [currentData, setCurrentData] = useState(data.slice(0, dataLimit));
  const { debouncedSearchText, onSearchChange } = useSimpleSearch('', 500, 3);

  useEffect(() => {
    if (debouncedSearchText.length === 0) {
      setCurrentData(data.slice(0, dataLimit));
      return;
    }

    const result = data.filter(
      (item) =>
        item.value.includes(debouncedSearchText) ||
        item.title.includes(debouncedSearchText)
    );

    setCurrentData(result);
  }, [debouncedSearchText]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = (e.target.value as string).toLowerCase();
    onSearchChange(query);
  };

  const handleSelectItem = (item: SearchModalData) => () => {
    onSelect(item);
    handleClose();
  };

  const handleClose = () => {
    onSearchChange('');
    onClose();
  };

  return (
    <Dialog disableEscapeKeyDown open={open} onClose={onClose}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        <Box component='form' sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <FormControl fullWidth sx={{ minWidth: 120 }}>
            <TextField
              placeholder='Type to Search..'
              variant='outlined'
              onChange={handleSearchChange}
            />
            {description && (
              <InputLabel htmlFor='demo-dialog-native'>
                {description}
              </InputLabel>
            )}
          </FormControl>
          <div className='SearchModal__results'>
            {currentData.map((item) => (
              <div className='SearchModal__item'>
                <Avatar
                  alt=''
                  src={getMediaFile(item.image)}
                  sx={{
                    width: 28,
                    height: 28,
                    bgcolor: deepPurple[50]
                  }}
                />
                <div className='SearchModal__title'>{item.title}</div>
                <div className='SearchModal__action'>
                  <Button
                    size='small'
                    variant='outlined'
                    color='secondary'
                    onClick={handleSelectItem(item)}
                  >
                    Select
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='secondary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SearchModal;
