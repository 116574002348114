import appToast from '../../core/toast';
import './CopyBtn.scss';

const CopyToClipboardButton = ({ textToCopy }: { textToCopy: string }) => {
  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      appToast.showSuccess('Invitation Url copied to clipboard!');
    } catch (err) {
      appToast.showSuccess('Something went wrong!');
    }
  };

  return (
    <button onClick={handleCopyClick} className='CopyBtn__copy-button'></button>
  );
};

export default CopyToClipboardButton;
