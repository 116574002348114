import { useEffect, useState } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation
} from 'react-router-dom';
import { RoutesEnum } from './enums';
import { IRoute } from './types';
import SignIn from '../pages/SignIn/SignIn';
import {
  useAppSelector,
  useAppDispatch,
  signInUser,
  logOutUser,
  addAllUsers,
  addAllCategories,
  getAllBookingReports,
  addAllBookingReports,
  getAllVideoReports,
  addAllVideoReports,
  getAllVideos,
  addAllVideos,
  appendBookingLogs
} from '../state';
import Loader from '../components/Loader/Loader';
import {
  getAllCategories,
  getBookingLogsV2,
  getUser,
  getUsers,
  logOut
} from './api';
import Moderate from '../pages/Moderate/Moderate';
import Users from '../pages/Users/Users';
import Bookings from '../pages/Bookings/Bookings';
import EventsPage from '../pages/EventsPage/EventsPage';
import {
  createExtendedExperienceLogModel,
  createExtendedUserModel
} from './helpers/model-helpers';
import Transactions from '../pages/Transactions/Transactions';
import Cms from '../pages/Cms/Cms';
import Statistic from '../pages/Statistic/Statistic';
import Coupons from '../pages/Coupons/Coupons';
import { BOOKING_LOGS_PER_PAGE } from './consts';
import InvitationCode from '../pages/InvitationCode/InvitationCode';

const noAuthenticatedRoutes: IRoute[] = [
  {
    path: RoutesEnum.SignIn,
    exact: true,
    component: SignIn
  },
  {
    type: 'redirect',
    path: '*',
    redirectTo: RoutesEnum.SignIn
  }
];

const authenticatedRoutes: IRoute[] = [
  // {
  //   type: 'redirect',
  //   path: RoutesEnum.Home,
  //   redirectTo: RoutesEnum.Bookings
  // },
  {
    path: '/invitations/:id',
    component: InvitationCode
  },
  {
    type: 'redirect',
    path: RoutesEnum.Bookings,
    exact: true,
    redirectTo: RoutesEnum.EventBookings
  },
  {
    path: [
      RoutesEnum.EventBookings,
      RoutesEnum.ExperienceBookings,
      RoutesEnum.BookingLogs
    ],
    component: Bookings
  },
  {
    path: RoutesEnum.Events,
    component: EventsPage
  },
  {
    path: RoutesEnum.Transactions,
    component: Transactions
  },
  {
    path: RoutesEnum.Statistics,
    component: Statistic
  },
  {
    path: RoutesEnum.Moderate,
    component: Moderate
  },
  {
    type: 'redirect',
    path: RoutesEnum.Cms,
    exact: true,
    redirectTo: RoutesEnum.Banners
  },
  {
    type: 'redirect',
    path: RoutesEnum.Coupons,
    exact: true,
    redirectTo: RoutesEnum.CouponList
  },
  {
    path: RoutesEnum.Users,
    exact: true,
    component: Users
  },
  {
    path: [RoutesEnum.CouponList, RoutesEnum.CouponReport],
    component: Coupons
  },
  {
    path: [RoutesEnum.Banners, RoutesEnum.Categories],
    component: Cms
  },
  {
    type: 'redirect',
    path: '*',
    redirectTo: RoutesEnum.Bookings
  }
];

const cleanParams = (pathname: string) => {
  if (pathname.includes('/invitations/')) {
    return '/invitations/';
  }
  return pathname;
};

const Routes = () => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(true);
  const { pathname } = useLocation();
  const history = useHistory();
  const routes: IRoute[] = auth.user
    ? authenticatedRoutes
    : noAuthenticatedRoutes;

  useEffect(() => {
    if (!auth.user) return;

    //Admin Account tries to access without accesslevel set
    if (!auth.user?.accessLevel) {
      logOut().then(() => dispatch(signInUser(null)));
      return;
    }
    const authPathname = cleanParams(pathname);
    const hasAccessToLocation =
      auth.accessPermissionAreas.includes(authPathname);
    !hasAccessToLocation && history.push(auth.accessPermissionAreas[0]);
  }, [pathname, auth.user]);

  useEffect(() => {
    const getInitData = async () => {
      try {
        if (!auth.user) {
          const _user = await getUser();
          if (_user) dispatch(signInUser(_user));
          return;
        }

        // Log out active sessions that are not admin accounts
        if (!auth.user.isAdmin) {
          await logOut();
          dispatch(signInUser(null));
          return;
        }

        setIsLoading(true);

        if ([1, 2, 3].includes(auth.user.accessLevel)) {
          const _bookingLogs = await getBookingLogsV2(
            null,
            0,
            BOOKING_LOGS_PER_PAGE
          );
          const bookingLogs = _bookingLogs.map(
            createExtendedExperienceLogModel
          );

          dispatch(appendBookingLogs(bookingLogs));
        }

        if ([1, 2].includes(auth.user.accessLevel)) {
          const bookingReports = await getAllBookingReports();
          dispatch(addAllBookingReports(bookingReports));

          const videoReports = await getAllVideoReports();
          dispatch(addAllVideoReports(videoReports));

          const videos = await getAllVideos();
          dispatch(addAllVideos(videos));

          const categories = await getAllCategories();
          dispatch(addAllCategories(categories));
        }

        if (auth.user.accessLevel === 1) {
          const _users = await getUsers(0, 50);
          const users = _users.map(createExtendedUserModel);
          dispatch(addAllUsers(users));

          // const _coupons = await getCouponList();
          // dispatch(addAllCoupons(_coupons));

          // const payouts = await getExperiencePayouts();
          // const statistics = createStatsFromExperiences(payouts);
          // console.log(statistics);
          // dispatch(setStatistics(statistics));
        }
      } catch (e: any) {
        if (auth && e?.response?.status === 401) {
          dispatch(logOutUser());
        }
      } finally {
        setIsLoading(false);
      }
    };

    getInitData();
  }, [auth.user]);

  if (isLoading) return <Loader />;

  return (
    <>
      <Switch>
        {routes.map((route) => {
          if (route.type === 'redirect') {
            return (
              <Route
                exact={route.exact}
                path={route.path}
                key={`redirect-${route.path}`}
              >
                <Redirect to={route.redirectTo || RoutesEnum.Home} />
              </Route>
            );
          }
          return (
            <Route key={(route.path as string) ?? route.path[0]} {...route} />
          );
        })}
      </Switch>
    </>
  );
};

export default Routes;
