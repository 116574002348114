import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { getMediaFile } from '../../../core/api';
import './BannerRow.scss';
import BaseRow, { IBaseRowProps } from '../BaseRow/BaseRow';
import { BannerViewModel } from '../../../core/backend/models';

interface IProps extends IBaseRowProps {
  banner: BannerViewModel;
  onSelect: React.MouseEventHandler;
  onEdit: React.MouseEventHandler;
  onDelete: React.MouseEventHandler;
}

const BannerRow = ({
  banner,
  selected,
  onSelect,
  onEdit,
  onDelete
}: IProps) => {
  return (
    <BaseRow selected={selected}>
      <TableCell padding='checkbox'>
        <Checkbox color='primary' checked={selected} onClick={onSelect} />
      </TableCell>
      <TableCell component='th' scope='row'>
        <img
          alt=''
          src={getMediaFile(banner.mediaFile.awsUrl)}
          width='300'
          height='150'
        />
      </TableCell>
      <TableCell style={{ verticalAlign: 'top' }} sx={{ maxWidth: '350px' }}>
        {banner.link}
      </TableCell>

      <TableCell align='center' style={{ verticalAlign: 'top' }}>
        <Button variant='text' color='primary' onClick={onEdit}>
          <EditIcon /> Edit
        </Button>
        <Button variant='text' color='error' onClick={onDelete}>
          <ClearIcon /> Delete
        </Button>
      </TableCell>
    </BaseRow>
  );
};

export default BannerRow;
